import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import { useIntl } from 'gatsby-plugin-intl'
import { useLocation } from '@reach/router'
import { Link, useStaticQuery, graphql, navigate } from 'gatsby'

import Layout from '../../components/common/layout'
import Container from '../../components/common/container'
import media from '../../components/css/media'
import Vault from './vault'

const TitleWrapper = styled.div`
  padding: 1rem;
  background-color: #323232;
`

const EmptyElement = styled.div`
  display: none;

  ${media.desktop`
    display: block;
    flex: 0 0 20%;
  `};
`

const Title = styled.h1`
  font-size: 1.8em;
  font-weight: 600;
  color: #fff;
  flex: 0 0 100%;

  ${media.desktop`
    flex: 0 0 80%;
  `};
`

const TitleContainer = styled.div`
  max-width: 100%;
  display: flex;
  flex-flow: row wrap;

  @media (min-width: 1200px) {
    margin: 0 auto;
    max-width: 1200px;
    padding: 0;
  }
`

const ContentWrapper = styled.div`
  display: flex;
  flex-flow: wrap;
`

const SideMenu = styled.div`
  display: none;
  margin: 1rem 0;

  ${media.desktop`
    display: flex;
    flex-direction: column;
    padding-right: 1.5rem;
    flex: 0 0 calc(20% - 1.5rem);
  `};
`

const ContentArea = styled.div`
  flex: 0 0 100%;
  padding: 0.6rem 0 0 0;
  line-height: 1.2;

  strong {
    font-weight: 600;
  }

  ${media.desktop`
    flex: 0 0 80%;
  `};
`

const Href = styled(Link)`
  color: #000;
  padding: 0.8rem 0;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &.active {
    text-decoration: underline;
    font-weight: 600;
  }
`

const Content = ({ pageContext: { slug } }) => {
  const location = useLocation()
  const intl = useIntl()

  const [activeTab, setActiveTab] = useState(location.pathname)

  const data = useStaticQuery(graphql`
    {
      pagesApi {
        values {
          lang
          contentMenu {
            menu_name
            menu_item_page_menu {
              link {
                url
                title
                target
              }
            }
          }
          pages {
            slug
            excerpt {
              rendered
            }
            title {
              rendered
            }
            content {
              rendered
            }
          }
        }
      }
      menuApi {
        values {
          lang
          menuData {
            className
            link {
              url
            }
          }
        }
      }
      footerApi {
        values {
          lang
          footerDesktopMenu {
            sub_items {
              slug
              external_link {
                url
              }
              left_side_menu_items {
                item_name
                slug_url
              }
            }
          }
        }
      }
    }
  `)

  const {
    pagesApi: { values },
    menuApi: { values: menuValues },
    footerApi: { values: sideMenuValues },
  } = data

  const dataExistForLocale = values.some((row) => row.lang === intl.locale)
  const dataExistForPage =
    dataExistForLocale &&
    values.filter((row) => row.lang === intl.locale)[0].pages
  const pages = dataExistForPage
    ? values.filter((row) => row.lang === intl.locale)[0].pages
    : []

  const menuExistForLocale = menuValues.some((row) => row.lang === intl.locale)
  const menuExistForPage =
    menuExistForLocale &&
    menuValues.filter((row) => row.lang === intl.locale)[0].menuData
  const menuData = menuExistForPage
    ? menuValues.filter((row) => row.lang === intl.locale)[0].menuData
    : null

  const sideMenuExistForLocale = sideMenuValues.some(
    (row) => row.lang === intl.locale
  )
  const sideMenuExistForPage =
    sideMenuExistForLocale &&
    sideMenuValues.filter((row) => row.lang === intl.locale)[0]
      .footerDesktopMenu
  const sideMenuData = sideMenuExistForPage
    ? sideMenuValues.filter((row) => row.lang === intl.locale)[0]
        .footerDesktopMenu
    : null

  let sideMenu = []
  sideMenuData.forEach((items) => {
    items.sub_items.forEach((row) => {
      if (row.left_side_menu_items.some((r) => r.slug_url.indexOf(slug) > 0)) {
        sideMenu = row.left_side_menu_items
      }

      if (sideMenu.length === 0 && row.external_link.url.indexOf(slug) > 0) {
        sideMenu = row.left_side_menu_items
      }
    })
  })

  const cashbackCta = menuData.some((m) => m.className === 'cta cashback')
    ? menuData.filter((m) => m.className === 'cta cashback')[0]
    : null

  let isVaultPage = false
  const urlPaths = cashbackCta.link.url.split('/')
  urlPaths.forEach((path) => {
    if (path === slug) {
      isVaultPage = true
    }
  })

  let page = pages.filter((page) => page.slug === slug)
  if (page.length === 0 && typeof window !== 'undefined') navigate('/404')

  page = page[0]

  if (typeof page === 'undefined') return <div />

  if (isVaultPage) return <Vault page={page} />

  return (
    <Layout>
      <Helmet>
        <title>{page.title.rendered}</title>
        <meta name='description' content={page.excerpt.rendered} />
      </Helmet>
      <TitleWrapper>
        <TitleContainer>
          <EmptyElement />
          <Title>{page.title.rendered}</Title>
        </TitleContainer>
      </TitleWrapper>
      <Container>
        <ContentWrapper>
          <SideMenu>
            {sideMenu.map((menu, i) => {
              return (
                <Href
                  key={i}
                  onClick={() => setActiveTab(menu.slug_url)}
                  className={`${
                    `/` + intl.locale + menu.slug_url === activeTab
                      ? 'active'
                      : ''
                  }`}
                  to={`/${intl.locale}${menu.slug_url}`}
                  title={`${menu.item_name}`}
                >
                  {menu.item_name}
                </Href>
              )
            })}
          </SideMenu>
          <ContentArea
            dangerouslySetInnerHTML={{ __html: page.content.rendered }}
          />
        </ContentWrapper>
      </Container>
    </Layout>
  )
}

export default Content
