import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'

import Layout from '../../components/common/layout'
import Container from '../../components/common/container'
import Btn from '../../components/common/button'
import { AuthContext } from '../../context/authProvider'
import storageService from '../../services/storageService'

import cashback from '../../images/cashback.gif'
import { cashoutVault } from '../../adapters/user'
import toast from '../../components/common/toast'
import FormatAmount from '../../components/common/formatAmount'
import media from '../../components/css/media'

const Wrapper = styled.div`
  padding: 2rem;
  background-color: #343434;
  color: #fff;
`

const LoggedInWrapper = styled.div`
  margin: 0 0 3rem 0;
  text-align: center;

  img {
    margin: 0 auto;
    width: 100%;
    height: auto;
  }

  ${media.mobile`
    img {
      width: 400px;
      height: 400px;
    }
  `};
`

const ContentWrapper = styled.div`
  display: flex;
  flex-flow: wrap;
`

const Username = styled.div`
  font-size: 2.75em;
  padding: 1rem 0 0 0;
`

const Cashback = styled.div`
  text-transform: uppercase;
  color: #fad749;
  letter-spacing: 10px;
  padding: 1rem 0;
`

const Balance = styled.div`
  font-size: 2.5em;
  line-height: 80px;
`

const ContentArea = styled.div`
  line-height: 1.2;

  strong {
    font-weight: 600;
  }

  a {
    color: #fff;
  }

  img {
    width: 100%;
    height: auto;
  }
`

const Vault = ({ page }) => {
  const intl = useIntl()
  const { isLoggedIn, getUserWallet } = useContext(AuthContext)
  const user = storageService.getUser()

  const handleCashout = () => {
    if (user.wallet.vault_balance === 0) return

    const model = {
      amount: user.wallet.vault_balance,
    }

    cashoutVault(model)
      .then((_) => {
        getUserWallet()
        toast.success(intl.formatMessage({ id: 'vault.pointsClaimed' }))
        setTimeout(() => window.location.reload(), 3000)
      })
      .catch((_) => {})
  }

  return (
    <Layout>
      <Helmet>
        <title>{page.title.rendered}</title>
        <meta name='description' content={page.excerpt.rendered} />
      </Helmet>
      <Wrapper>
        <Container>
          {isLoggedIn && (
            <LoggedInWrapper>
              <Username>
                {user.first_name} {user.last_name}
              </Username>
              <Cashback>
                <FormattedMessage id='vault.header' />
              </Cashback>
              <img src={cashback} height={400} width={400} alt='' />
              <Balance>
                <FormatAmount
                  amount={user.wallet.vault_balance}
                  currency={user.wallet.currency.short_code}
                />
              </Balance>
              <div>
                <Btn
                  text={intl.formatMessage({ id: 'vault.cashout' })}
                  bgcolor='#31c27c'
                  bordercolor='#31c27c'
                  padding='.5rem 2rem'
                  className={user.wallet.vault_balance === 0 ? 'disabled' : ''}
                  disabled={user.wallet.vault_balance === 0}
                  onClick={handleCashout}
                />
              </div>
              <small>
                <FormattedMessage id='vault.note' />
              </small>
            </LoggedInWrapper>
          )}

          <ContentWrapper>
            <ContentArea
              dangerouslySetInnerHTML={{ __html: page.content.rendered }}
            />
          </ContentWrapper>
        </Container>
      </Wrapper>
    </Layout>
  )
}

export default Vault
